import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import * as styles from "../components/index.module.css";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Logo from "../components/logo"
import Tagline from "../components/tagline"
import Bio from "../components/bio"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allCakesYaml {
        edges {
          node {
            title
            size
            date
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  breakpoints: [290, 360, 490, 750, 1140]
                )
              }
            }
          }
        }
      }
    }
  `);
  const cakes = data.allCakesYaml.edges.slice().reverse().reduce((acc, val) => {acc.push(val.node); return acc}, []);

  return (
    <Layout>
      <SEO
        title="PhillyCake – Custom cakes in Philadelphia, PA"
        description="American and European cakes, pastries, and desserts in Philadelphia’s Main Line."
        lang="en"
      />

      <div className={styles.grid}>
        <div className={[styles.card, styles.cardLogo].join(' ')}>
          <Logo siteTitle="PhillyCake" />
        </div>

        <div className={[styles.card, styles.cardTagline].join(' ')}>
          <Tagline />
        </div>

        {cakes.map((cake, index) => {
          const { gatsbyImageData } = cake.image.childImageSharp;
          const aspectRatio = gatsbyImageData.width / gatsbyImageData.height;
          const orientation = aspectRatio < 1 ? styles.card3x4 : styles.card6x4;
          const size = cake.size !== null && styles['card' + cake.size];
          const date = new Date(cake.date).toLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric' });

          return (
            <div key={index} className={[styles.card, styles.cardPhoto, size || orientation].join(' ')}>
              <GatsbyImage
                image={gatsbyImageData}
                alt={`${cake.title} — ${date}`}
                title={`${cake.title} — ${date}`}
              />
            </div>
          );
        })}

        <Bio />
      </div>
    </Layout>
  );
}

export default IndexPage

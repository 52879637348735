import React from "react"
import { container, desc, contacts, linkInstagram } from "./tagline.module.css"

const Tagline = () => (
  <div className={container}>
    <h1 className={desc}>
      American &amp; European cakes,&nbsp;pastries, and&nbsp;desserts in&nbsp;Philadelphia’s Main&nbsp;Line.
    </h1>
    <ul className={contacts}>
      <li><a href="mailto:natalya@fedorenko.me">natalya@fedorenko.me</a></li>
      <li><a href="https://www.instagram.com/phillycakebakery/"><span className={linkInstagram} />@phillycakebakery</a></li>
    </ul>
  </div>
)

export default Tagline

import React from "react"
import { card, cardBio } from "./index.module.css";
import { bio, portraitImg } from "./bio.module.css"
import portrait from "./natalya-portrait.jpg"

const Bio = () => (
  <div className={[card, cardBio, bio].join(' ')}>
    <img src={portrait} alt="Natalya Fedorenko" className={portraitImg} />
    Natalya Fedorenko is a home baker in Ardmore, PA. She picked up baking as a hobby while being on maternity leave in 2010 and over time it has become her true passion. In 2011 she began a second degree in baking from Odessa National Academy of Food Technologies, but unfortunately wasn't able to finish as her family prepared their move to the United States. Since moving to the US, she has learned how to bake American specialties and worked as a baker in the popular <a href="https://www.thebakeryhouse.net" target="_blank" rel="noopener noreferrer">The Bakery House</a> in Bryn Mawr, PA. In 2019 Natalya certified her home kitchen and started taking orders!
  </div>
)

export default Bio

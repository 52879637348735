// extracted by mini-css-extract-plugin
export var card = "index-module--card--HVafm";
export var card3x2 = "index-module--card3x2--drC2E";
export var card3x4 = "index-module--card3x4--MCZlS";
export var card4x3 = "index-module--card4x3--pGAG6";
export var card4x5 = "index-module--card4x5--uFobL";
export var card4x6 = "index-module--card4x6--UlUXR";
export var card6x3 = "index-module--card6x3--6JTYF";
export var card6x4 = "index-module--card6x4--eEo79";
export var card6x9 = "index-module--card6x9--dNj4Z";
export var card8x5 = "index-module--card8x5--ei4UO";
export var card9x6 = "index-module--card9x6--ng94z";
export var cardBio = "index-module--cardBio--reD2S";
export var cardBioPartner = "index-module--cardBioPartner--bATeE";
export var cardLogo = "index-module--cardLogo--M4l52";
export var cardPhoto = "index-module--cardPhoto--grJ7m";
export var cardTagline = "index-module--cardTagline--1tMRU";
export var cardTaglinePartner = "index-module--cardTaglinePartner--4rv1x";
export var grid = "index-module--grid--ldSlR";